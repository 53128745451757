<template>
  <SfOModal
    :overlay="!isMobile"
    :visible="isMicrocartOpen && isMicrocartVisible"
    class="o-microcart-panel"
    :class="classNameModal"
    :title="$t('Basket')"
    transition-modal="to-left"
    @close="$store.dispatch('ui/setMicrocart')"
  >
    <template #undermodal>
      <ALoadingSpinner
        v-if="!isDesktop"
        v-show="isCheckoutLoading"
        :is-fixed-position="true"
      />
    </template>
    <template #close>
      <span class="close-icon" />
    </template>
    <template #modal-bar>
      <transition name="fade" mode="out-in">
        <div
          class="o-microcart-panel__bar"
          v-if="totalItems"
        >
          <SfHeading
            :title="$t('Basket')"
            :level="3"
          >
            <template #subtitle>
              <ALoadingSpinner
                v-show="modalLoadingState"
                :is-absolute-position="true"
              />
            </template>
          </SfHeading>
        </div>
      </transition>
    </template>
    <div
      class="o-microcart-panel__scrollable"
      :class="{
        'o-microcart-panel__scrollable--overscroll': productRelated.length && totalItems && !isCheckoutPage
      }"
    >
      <transition name="fade" mode="out-in">
        <div v-if="totalItems" key="my-cart" class="my-cart-mini">
          <MSummaryNotification
            ref="summaryNotification"
            class="o-microcart-panel__element--padding o-microcart-panel__error"
            :cart-validation-message="cartValidationMessage"
            :cart-validation-warning="cartValidationWarning"
            :is-warning-show="false"
          />

          <div
            class="o-microcart-panel__head o-microcart-panel__element--padding"
            id="o-microcart-panel-head"
          >
            <div
              class="o-microcart-panel__head-top"
            >
              <div class="o-microcart-panel__head-buttons">
                <AAddCartToWishlist />
                <AAddToCartFromWishlist v-if="!isCheckoutPage" />
              </div>
            </div>
          </div>

          <div class="o-microcart-panel__list">
            <div
              v-if="!!skippedRules.length"
              :class="{'o-microcart-panel__element': !isMobile}"
            >
              <OMicrocartRules :items="skippedRules" @revert="revertRule" />
            </div>

            <MMicrocartList
              :is-gift="true"
              :items="productsInCartSorted.gifts"
              :cart-sqpp="getCartSQPP"
              :is-last-product-in-cart="isLastProductInCart"
              :cart-states="cartStates"
              :product-stock="productStock"
            />

            <MReplacementCart
              v-if="hasOutOfStock"
              :products="productsInCartSorted.notAvailable"
              :cart-states="cartStates"
              :product-stock="productStock"
            />

            <MMicrocartList
              :items="productsInCartSorted.notAvailable"
              class="o-microcart-panel__oos-list"
              :cart-sqpp="getCartSQPP"
              :is-last-product-in-cart="isLastProductInCart"
              :cart-states="cartStates"
              :product-stock="productStock"
              :product-related="getProductRelated"
            >
              <div class="o-microcart-panel__element o-microcart-panel__oos-header">
                {{ $t('The product is out of stock') }}
              </div>
            </MMicrocartList>

            <MMicrocartList
              :items="productsInCartSorted.notForNP"
              class="o-microcart-panel__oos-list"
              :cart-sqpp="getCartSQPP"
              :is-last-product-in-cart="isLastProductInCart"
              :cart-states="cartStates"
              :product-stock="productStock"
              :is-new-post="isNewPost"
            >
              <div class="o-microcart-panel__element o-microcart-panel__oos-header">
                {{ $t('Out of stock NP Title') }}
              </div>
            </MMicrocartList>

            <MMicrocartList
              v-if="!!productsInCartSorted.promotions.length"
              :items="productsInCartSorted.promotions"
              :cart-sqpp="getCartSQPP"
              :is-last-product-in-cart="isLastProductInCart"
              :cart-states="cartStates"
              :product-stock="productStock"
            />

            <MMicrocartList
              :items="productsInCartSorted.available"
              :cart-sqpp="getCartSQPP"
              :is-last-product-in-cart="isLastProductInCart"
              :cart-states="cartStates"
              :product-stock="productStock"
            />
          </div>
        </div>
        <div
          v-else
          key="empty-cart"
          class="o-microcart-panel__empty"
        >
          <MMicrocartEmpty
            data-transaction-name="Microcart - Close empty"
            @click="closeMicrocartExtend()"
          />
        </div>
      </transition>
      <transition name="fade">
        <div
          v-if="totalItems"
          class="o-microcart-panel__element--padding o-microcart-panel__total"
        >
          <ATotalsPlaceholder v-if="hideSummaryBlock" />

          <MPriceSummaryCart
            v-else
            class="o-microcart-panel__hon"
            :class="{
              'o-microcart-panel__hon--hide': hideSummaryBlock
            }"
            :show-price="false"
            :is-large="true"
            :total-weight="totalWeight"
          />
        </div>
      </transition>

      <transition name="fade">
        <div
            v-if="totalItems && !isCheckoutPage"
            class="o-microcart-panel__products"
            :class="{ 'o-microcart-panel__has-overweight': maxWeight.invalid && !hasOutOfStock }"
        >
          <MCrossaleCartProducts :related-list="productRelated" />
        </div>
      </transition>
    </div>
    <transition name="fade">
      <div v-if="totalItems" class="o-microcart-panel__action">
        <MMicrocartFreeShipping />
        <MDottedLoader v-if="hideSummaryBlock" :loading="true" />
        <SfButton
          class="sf-button--full-width sf-button--base sf-button--primary"
          data-transaction-name="Microcart - Go To Checkout"
          @click.native="handleCheckout"
          v-if="isCanGoToCheckout && !isCheckoutPage"
          :disabled="hideSummaryBlock"
        >
          {{ $t('Go to checkout') }}
          <template v-if="getCartTotal">
            ({{ getCartTotal | price }})
          </template>
        </SfButton>
        <SfButton
          class="sf-button--full-width sf-button--base sf-button--primary"
          data-transaction-name="Microcart - Continue Checkout"
          @click.native="closeMicrocartExtend"
          v-else-if="isCanGoToCheckout"
          :disabled="hideSummaryBlock"
        >
          {{ $t('Continue') }}
        </SfButton>
      </div>
    </transition>
  </SfOModal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import VueOfflineMixin from 'vue-offline/mixin';
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress';
import { eSputnikEvent } from 'theme/helpers/es';
import {
  SfButton,
  SfHeading
} from '@storefront-ui/vue';
import MMicrocartEmpty from 'theme/components/molecules/m-microcart-empty';
import config from 'config';
import MPriceSummaryCart from 'theme/components/molecules/m-price-summary-cart';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner';
import SfOModal from 'theme/components/storefront-override/SfOModal';
import MCrossaleCartProducts from 'theme/components/molecules/m-crossale-cart-products';
import MMicrocartList from 'theme/components/molecules/m-microcart-list';
import MReplacementCart from 'theme/components/molecules/m-replacement-cart';
import { weightGToKg } from 'theme/filters'
import { getProductWeight } from 'theme/helpers/product'
import DeviceType from 'theme/mixins/DeviceType';
import GoogleTagManager from 'theme/mixins/gtm'
import OMicrocartRules from 'theme/components/organisms/o-microcart-rules.vue';
import AAddCartToWishlist from 'theme/components/atoms/a-add-cart-to-wishlist.vue';
import AAddToCartFromWishlist from 'theme/components/atoms/a-add-to-cart-from-wishlist.vue';
import Delivery from 'theme/mixins/Delivery';
import MDottedLoader from 'theme/components/molecules/m-dotted-loader.vue';
import ATotalsPlaceholder from 'theme/components/atoms/a-totals-placeholder.vue';
import ScrollLock from 'theme/mixins/ScrollLock';
import MMicrocartFreeShipping from 'theme/components/molecules/m-microcart-free-shipping.vue';
import MSummaryNotification from 'theme/components/molecules/modals/m-summary-notification.vue';
import CartValidations from 'theme/mixins/CartValidations';

export default {
  name: 'OMicrocartPanel',
  components: {
    MMicrocartFreeShipping,
    ATotalsPlaceholder,
    MDottedLoader,
    AAddToCartFromWishlist,
    MPriceSummaryCart,
    MMicrocartEmpty,
    SfButton,
    SfOModal,
    SfHeading,
    ALoadingSpinner,
    MCrossaleCartProducts,
    MMicrocartList,
    MReplacementCart,
    AAddCartToWishlist,
    OMicrocartRules,
    MSummaryNotification
  },
  mixins: [VueOfflineMixin, onEscapePress, DeviceType, GoogleTagManager, Delivery, CartValidations, ScrollLock],
  data () {
    return {
      toggleProductSort: true,
      isDisableGoToCheckout: false,
      isMicrocartVisible: false,
      isLoadingState: false,
      isCheckoutLoading: false,
      isViewEventSent: false,
      getSpecialOffersCategoryId: null,
      isQtySync: false
    };
  },
  provide () {
    return {
      isMicroCart: true
    }
  },
  watch: {
    isMicrocartOpen: {
      immediate: true,
      handler: function (val) {
        this.toggleBodyFixedClass(val);

        if (val) {
          this.syncMicrocartIfApplicable();
        } else {
          this.emitMicrocartStateChange();
        }

        if (!val) {
          this.isViewEventSent = false
          return
        }

        this.emitShowCartEvent()

        this.admitadCart(this.productsInCart)
      }
    },
    isCartSync (val) {
      if (this.totalItems && !val) {
        this.esputnikCartEvent();
        this.getProductPriceAndStock()
      }
    },
    getCartSQPP (val) {
      if (!Array.isArray(val) || val.length === 0) return

      this.getProductPriceAndStock()
    }
  },
  computed: {
    ...mapState({
      isMicrocartOpen: state => state.ui.microcart,
      isCartConnecting: state => state.cart.isConnecting,
      isSyncingTotal: state => state.cart.isSyncingTotal,
      isCartSync: state => state.cart.isSyncing,
      isDeliveryLoader: state => state.ui.deliveryLoader,
      isShippingDetailSave: state => state.shipping.loading.shippingDetailsSave
    }),
    ...mapGetters({
      getSkippedRules: 'cart/getSkippedRules',
      productsInCart: 'cart/getCartItems',
      totals: 'cart/getTotals',
      currentCartHash: 'cart/getCurrentCartHash',
      cartStates: 'cart/getCartStates',
      productStock: 'cart/getProductStock',
      getCartTotal: 'cart/getCartTotal',
      getTotals: 'cart/getTotals',
      shippingDetails: 'shipping/getShippingDetails',
      getCartSQPP: 'cart/getCartSQPP',
      isCheckoutPage: 'checkout/isCheckoutPage',
      getProductRelated: 'product/getProductRelated',
      isNewPost: 'shipping-module/isCurrentNewPost'
    }),
    productRelated () {
      return this.getProductRelated['crossaleCartProducts'] || []
    },
    modalLoadingState () {
      if (!this.isDesktop) {
        return this.isLoadingState
      }

      return this.isLoadingState || this.isCheckoutLoading
    },
    isCartLoading () {
      return this.isCartSync ||
          this.isSyncingTotal ||
          this.isDeliveryLoader ||
          this.isShippingDetailSave ||
          this.isCartConnecting
    },
    isLastProductInCart () {
      return this.productsInCart.length === 1;
    },
    checkoutBlocked () {
      return this.isDisableGoToCheckout || this.isCartLoading
    },
    cartStatesReplacements () {
      return this.cartStates.productStock.replacements.map(i => i.sku)
    },
    hasOutOfStock () {
      if (!this.cartStatesReplacements.length) {
        return false
      }

      return !!this.productsInCartSorted.notAvailable.length
    },
    hasOutOfStockNP () {
      return !!this.productsInCartSorted.notForNP.length
    },
    hideSummaryBlock () {
      return !this.isLoadingState && this.checkoutBlocked && !this.isCheckoutLoading
    },
    totalItems () {
      return this.productsInCart.length;
    },
    productsCount () {
      let count = 0;
      this.productsInCart.forEach(product => {
        count = count + parseInt(product.qty);
      });
      return count;
    },
    classNameModal () {
      return {
        'o-microcart-panel--empty': !this.totalItems,
        'o-microcart-panel--checkout': this.isCheckoutPage
      }
    },
    isValid () {
      return this.cartStates.shippingMethod.valid
    },
    sortOptions () {
      return Object.entries(config.cart.sortByAttributes).map(attribute => {
        const [label, id] = attribute;
        return { id, label };
      });
    },
    sortOrder () {
      return (
        `${config.cart.defaultSortBy.attribute}:${config.cart.defaultSortBy.order}`
      );
    },
    isCanGoToCheckout () {
      return this.totalItems && this.isValid
    },
    skippedRules () {
      return this.getSkippedRules
    },
    productsInCartSorted () {
      return this.getSkusInCartChangedState(this.productsInCart)
    },
    totalWeight () {
      const weight = this.productsInCart.reduce((result, product) => {
        return result + (getProductWeight(product) * product.qty);
      }, 0);

      return weightGToKg(weight)
    },
    maxWeight () {
      const maxWeight = this.cartStates.maxWeight

      return {
        invalid: !maxWeight.valid
      }
    }
  },
  methods: {
    ...mapActions({
      sync: 'cart/sync',
      revertRule: 'cart/revertRule',
      syncTotals: 'cart/syncTotals',
      checkCartStateProductStockAndProductPrices: 'cart/checkCartStateProductStockAndProductPrices'
    }),
    getSkusInCartChangedState (productList) {
      const replacementsSku = this.getSkusArray('productStock', 'replacements')

      const { available, gifts, promotions, notForNP } = productList
        .reduce((a, c) => {
          if (replacementsSku.includes(Number(c.sku))) return a

          if (this.isNewPost && !c?.forNewPost) a.notForNP.push(c)
          else if (c?.gift?.isGift) a.gifts.push(c)
          else if (c?.gift?.isPromotion) a.promotions.push(c)
          else a.available.push(c)

          return a
        }, {
          available: [],
          gifts: [],
          promotions: [],
          notForNP: []
        })

      return {
        notAvailable: this.remapSkus(replacementsSku),
        available: available.reverse(),
        gifts: this.sortedByDate(gifts),
        promotions: this.sortedByDate(promotions),
        notForNP: notForNP
      }
    },
    sortedByDate (items) {
      return items.sort((a, b) => {
        if (new Date(b.extension_attributes?.created_at) - new Date(a.extension_attributes?.created_at)) return -1
        if (!a.extension_attributes) return -1
      })
    },
    remapSkus (skus) {
      const items = skus
        .reduce((a, c) => {
          const item = this.productsInCart.find(p => Number(p.sku) === c)

          if (item) {
            a.push(item)
          }

          return a
        }, [])

      return items
    },
    getSkusArray (productsType, key) {
      if (this.cartStates[productsType][key].length) {
        return this.cartStates[productsType][key].map(filteredProduct => Number(filteredProduct.sku))
      } else {
        return []
      }
    },
    closeMicrocart () {
      this.$store.dispatch('cart/clearProductPriceState')
      this.$store.dispatch('ui/closeMicrocart')
    },
    closeMicrocartExtend () {
      if (this.$refs?.summaryNotification?.cartValidationMessage) {
        this.goToCartTop()
        return
      }

      if (this.isCheckoutPage) {
        this.$store.dispatch('cart/clearNotEnough')
      }

      this.closeMicrocart()
    },
    onEscapePress () {
      this.closeMicrocartExtend();
    },
    async handleCheckout () {
      if (this.$refs?.summaryNotification?.cartValidationMessage) {
        this.goToCartTop()
        return
      }

      this.isCheckoutLoading = true

      try {
        await this.syncTotalsAndGetProductPriceAndStock()
        this.$store.commit(`checkout/checkout/SET_ACTIVE_STEP`)

        return this.goToCheckout()
      } catch (e) {
        this.isCheckoutLoading = false
      }
    },
    goToCheckout () {
      this.closeMicrocartExtend()
      this.$router.push(localizedRoute('/checkout'))
    },
    goToCartTop () {
      const productPriceStateElement = document.querySelector('.my-cart-mini')

      if (productPriceStateElement) {
        productPriceStateElement.scrollIntoView({ behavior: 'smooth' })
      }

      this.$refs?.summaryNotification?.blink()
    },
    toggleDisableGoToCheckout (value) {
      this.isDisableGoToCheckout = value;
    },
    setIsQtySync (product) {
      this.isQtySync = !!product.fromEvent
    },
    async syncTotalsAndGetProductPriceAndStock () {
      this.isDisableGoToCheckout = true

      await this.checkCartStateProductStockAndProductPrices()

      this.isDisableGoToCheckout = false

      this.$bus.$emit('product-quantity-stock-changed')
    },
    async getProductPriceAndStock () {
      if (this.isQtySync) {
        this.isQtySync = false
        return
      }

      this.isDisableGoToCheckout = true

      await this.checkCartStateProductStockAndProductPrices()

      this.isDisableGoToCheckout = false

      this.$bus.$emit('product-quantity-stock-changed')
    },
    async esputnikCartEvent () {
      eSputnikEvent('StatusCart', { productsInCart: this.productsInCart, currentCartHash: this.currentCartHash })
    },
    toggleBodyFixedClass (isMicrocartOpen) {
      if (isMicrocartOpen) {
        this.enableScrollLock()
        return
      }

      this.disableScrollLock()
    },
    syncMicrocartIfApplicable () {
      const shouldSync = this.totalItems && !this.isCartSync && !this.isSyncingTotal;
      if (shouldSync) {
        this.sync();
      }
    },
    emitMicrocartStateChange () {
      this.$bus.$emit('microcart-panel-state-changed');
    },
    emitShowCartEvent () {
      if (this.isViewEventSent) return
      const cartTotal = this.getCartTotal
      const collectingData = this.getTotals?.find(i => i.code === 'collecting')?.value || 0
      const value = Math.round((cartTotal - collectingData) * 100) / 100

      this.gtmProductsHandler(this.productsInCart, 'view_cart', {}, {
        value: value
      })

      this.isViewEventSent = true
    }
  },
  async mounted () {
    if (this.totalItems) {
      this.getProductPriceAndStock()
    }

    const {
      specialOffersCategoryId
    } = await this.$store.dispatch('category-extension/specialCategoriesIds')

    this.getSpecialOffersCategoryId = specialOffersCategoryId

    this.isMicrocartVisible = true;

    this.enableScrollLock()
  },
  beforeMount () {
    this.$bus.$on('show-cart-spinner', this.toggleDisableGoToCheckout)
    this.$bus.$on('shipping-details-changed', this.syncTotalsAndGetProductPriceAndStock)
    this.$bus.$on('product-quantity-state-changed', this.setIsQtySync)
    this.$bus.$on('cart-before-delete-products', () => {
      this.isLoadingState = true
    })
    this.$bus.$on('cart-after-delete-products', () => {
      this.isLoadingState = false
      this.esputnikCartEvent()
    })
    this.emitShowCartEvent()
  },
  beforeDestroy () {
    this.$bus.$off('show-cart-spinner', this.toggleDisableGoToCheckout)
    this.$bus.$off('shipping-details-changed', this.syncTotalsAndGetProductPriceAndStock)
    this.$bus.$off('product-quantity-state-changed', this.setIsQtySync)

    this.$bus.$off('cart-before-delete-products')
    this.$bus.$off('cart-after-delete-products')
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/px2rem";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';
@import "~theme/css/mixins";

.o-microcart-panel {
  --modal-close-right: var(--spacer-24)!important;

  @media (max-width: $mobile-max) {
    --modal-close-right: var(--spacer-16)!important;
  }

  @include for-desktop {
    --sidebar-bottom-padding: var(--spacer-base);
    --sidebar-content-padding: var(--spacer-base);
  }

  &__bar {
    padding: 0 var(--spacer-16);

    border-bottom: 1px solid var(--line-color);

    @media (min-width: $tablet-min) {
      padding: var(--spacer-16) var(--spacer-24) 0 var(--spacer-24);
    }
  }

  &__content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (min-width: $tablet-min) {
      padding: var(--spacer-15) 0 0 0;
    }
  }

  &__scrollable {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 100vw;
    flex: 1;

    &--overscroll {
      background: rgb(255, 255, 255);
      background: linear-gradient(180deg, rgba(255, 255, 255, 1) 50%, rgba(243, 244, 246, 1) 50%);
    }

    &::-webkit-scrollbar {
      position: absolute;
      top: 0;
      bottom:0;
      width: 9px;
      background: var(--white);
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-left: 3px solid var(--light-orange);

      &:hover {
        background-color: transparent;
        border-left: 3px solid var(--orange);
      }
    }
  }

  &__head {
    min-height: 60px;
    display: flex;
    align-items: center;
    background: var(--white);

    &-top {
      display: flex;
    }

    &-buttons {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    &__address {
      flex: 1;
    }
  }

  &__empty {
    height: 100%;
  }

  &__overflow {
    overflow: hidden;
  }

  &__list {
    flex: 1;
  }

  &__loading {
    z-index: 0!important;
  }

  &__trash {
    position: relative;
    margin-right: px2rem(7);
    top: px2rem(-2)
  }

  &__hon {
    transition: opacity 100ms;
    opacity: 1;

    &--hide {
      opacity: 0;
    }
  }

  &__total {
    flex: 1;
    position: relative;
    padding-top: 24px;
    background: var(--white);
  }

  &__action, &__invalid-weight {
    margin: auto 0 0;
    width: 100%;
    z-index: 1;
    bottom: 0;

    > button {
      margin: 0;
    }
    .shipping-method-state__button {
      height: auto;
    }

    &-notice {
      font-size: var(--font-sm);
      font-weight: normal;
      color: var(--orange);
      display: block;
      margin-top: var(--spacer-5);
      text-align: center;

      @media (max-width: $mobile-max) {
        margin: var(--spacer-5) 0;
      }
    }
  }

  &__action, &__invalid-weight {
    z-index: 1;
  }

  &__action {
    position: relative;
  }

  &__products {
    margin: auto 0 0;
  }

  &__has-overweight {
    @media (max-width: $mobile-max) {
      margin-bottom: var(--spacer-45)
    }
  }

  &__oos-header {
    font-style: normal;
    font-weight: var(--font-medium);
    font-size: var(--font-base);
    line-height: var(--font-size-19);
    margin: 0;
    padding-top: var(--spacer-10);
    padding-left: var(--spacer-8);
    background: var(--light-gray);

    @media (max-width: $mobile-max) {
      margin: 0!important;
    }
  }

  &__button {
    text-align: center;
    padding: var(--spacer-10) 0;
    display: none;

    @media (min-width: $tablet-min) {
      display: flex;
      justify-content: center;
    }
  }

  &__summary-limit {
    ::v-deep .property--weight .sf-property__value {
      color: var(--orange);
    }
  }

  &__error {
    padding-bottom: 0;
    background: var(--white);
  }

  ::v-deep &__element {
    margin-left: var(--spacer-16);
    margin-right: calc(var(--spacer-16) - 10px);

    @media (min-width: $tablet-min) {
      margin-left: var(--spacer-24)!important;
      margin-right: calc(var(--spacer-24) - 10px) !important;
    }

    &--padding {
      padding-right: calc(var(--spacer-16) - 10px);
      padding-left: var(--spacer-16);

      @media (min-width: $tablet-min) {
        padding-left: var(--spacer-24)!important;
        padding-right: var(--spacer-24) !important;
      }
    }
  }

  ::v-deep {
    .fade-list-wrapper {
      background: var(--white);
    }

    .sf-modal__content {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0;
      overflow-x: hidden;

      @media (min-width: $tablet-min) {
        padding: 0;
        margin: 0;
      }
    }

    .sf-modal__overlay {
      z-index: var(--microcart-panel-index-overlay, 6);
    }

    .sf-modal__container {
      overflow: hidden;
      transition: bottom 200ms;
      z-index: var(--microcart-panel-index, 7);
    }

    .sf-heading:not(.m-microcard-empty__heading) {
      display: flex;
      align-items: center;
      justify-content: start;
      padding: 1.063rem 0;

      @media (min-width: $tablet-min) {
        padding: 0 0 var(--spacer-20) 0;
      }

      .sf-heading__title {
        @include header-title;
        margin-right: px2rem(24);
      }
    }

    .m-user-address__address {
      margin: 0 0 0 var(--spacer-12);
      padding: 0;
      max-height: 2.1rem;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      /* autoprefixer: ignore next */
      -webkit-line-clamp: 2;
      /* autoprefixer: ignore next */
      -webkit-box-orient: vertical;

      &:after {
        content: none;
      }
    }

    .sf-modal__container .a-loading-spinner {
      z-index: var(--sf-overlay-index);
    }
  }

  &--empty {
    ::v-deep {
      @media (min-width: $tablet-min) {
        .sf-modal__content {
          padding: 0;
          max-height: 100%;
          height: 100%;
        }
      }

      .sf-modal__close {
        display: flex !important;
        z-index: 1;
      }

      @media (max-width: $mobile-max) {
        .sf-modal__container {
          z-index: var(--microcart-panel-index, 7);
          bottom: 0;
        }

        .sf-modal__content {
          padding: 0;
        }
      }
    }
  }

  &--checkout {
    ::v-deep {
      .sf-modal__container, .o-microcart-panel__action, .o-microcart-panel__invalid-weight {
        bottom: 0;
      }
    }
  }

  .close-icon {
    &:before {
      color: var(--black);
    }
  }
}

.my-cart-mini {
  display: block;

  &__total-items {
    margin: 0;
  }

  &__total-price {
    --price-font-size: var(--font-sm);
    --price-font-weight: var(--font-semibold);
    margin: 0 0 var(--spacer-xs) 0;
  }
}

::v-deep {
  .sf-sidebar__top {
    --sidebar-top-family: var(--font-family-secondary)
  }

  .property--grand-total {
    margin: 0 0 var(--spacer-20);
  }
}

.shipping-method-state {
  &__message {
    padding: var(--spacer-10) var(--spacer-10) var(--spacer-15) var(--spacer-35);
    background: var(--orange-light-background);
    font-size: var(--font-size-14);
    font-weight: normal;
    line-height: var(--font-size-17);
    color: var(--black);
    position: relative;

    &:before {
      content: '';
      background: url('~theme/assets/info.svg') 0 0 no-repeat no-repeat;
      background-size: contain;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 10px;
      left: 10px;

      @media (max-width: $mobile-max) {
        left: 0;
      }
    }

    @media (max-width: $tablet-max) {
      top:  var(--spacer-34);
    }

    span {
      display: inline-block;
      width: 100%;
      margin-bottom: var(--spacer-5);
    }

    @media (max-width: $mobile-max) {
      padding: var(--spacer-10) 0 var(--spacer-10) var(--spacer-20);

      &:after {
        content: '';
        position: absolute;
        left: calc(-1 * var(--spacer-20));
        right: calc(-1 * var(--spacer-20));
        top: 0;
        z-index: -1;
        height: 100%;
        background: inherit;
      }
    }
  }
}

.product-price-state {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacer-15);
  padding-top: var(--spacer-20);
  padding-bottom: var(--spacer-20);
  background: var(--light-gray);

  &__message {
    font-size: var(--font-size-14);
    line-height: var(--font-size-17);
    font-weight: var(--font-normal);
    color: var(--black);
    position: relative;
  }

  &__action {
    font-size: var(--font-size-14);
    line-height: var(--font-size-17);
    font-weight: var(--font-normal);
    color: var(--orange);
    cursor: pointer;
  }

  &__price-diff {
    font-size: var(--font-size-15);
    color: var(--orange);
  }
}

.o-microcart-panel {
  @media (min-width: $mobile-max) {
    --modal-close-top: #{px2rem(13)};
    --modal-close-right: var(--spacer-20);
    --modal-width: #{px2rem(600)};
    --modal-left: calc(100% - var(--modal-width));
    --modal-right: initial;
    --modal-top: 0;
    --modal-bottom: 0;
    --modal-height: auto;
    --modal-transform: initial;

    //@include for-tablet {
    //  --modal-top: var(--bar-height);
    //  //--modal-bottom: var(--bottom-navigation-height);
    //}

    ::v-deep {
      .to-left-leave-active,
      .to-left-enter-active {
        transition: .3s;
      }
      .to-left-enter {
        left: 100%;
        opacity: 0!important;
      }
      .to-left-leave-to {
        left: 100%;
        opacity: 0!important;
      }
    }

    ::v-deep .sf-modal__container {
      opacity: 1;
      max-height: 100%;
    }
  }
}

.o-microcart-panel {
  ::v-deep .sf-move-to-trash {
    position: relative;

    @include font-icon(var(--icon-trash));

    &:before {
      color: var(--orange);
      font-size: var(--font-size-28);
      line-height: 0;
      padding-right: 5px;
    }
  }
}
</style>
